import Config from "../data/config.json";

import { socket } from '../socket';

// import axios from "axios";

//constructor
const TriplePlayApihandler = (method, params, source, uid) => {
  // const domain = "10.20.0.40";
  // const domain = Config.serverdomain + ":3001";
  // const domain = "stlcsc-wvd-01.stlcitysc.com";
  // const baseUrl = Config.baseUrl;

  // const Url = `${domain}/${method}`;
  const data = { params: params, source: source, uid: uid, method: method };

  // axios.post(Url, data).then((res) => {
  //   console.log(res, method, params, Url);
  //   return res;
  // });


  // const Url = `https://${domain}/${route}`;
  // const data = { params: params, headers: headers, method: method }
  const methodRoute = `/${method}/${params}/`;

  // const Url = `https://${pin}:@${domain}${baseUrl}/${method}/${params}`;
  // const Url = `https://${domain}${baseUrl}/${method}/${params}/`;
  // console.log("url: ", Url);

  // axios.post(Url, data).then((res) => {
  //   console.log(res, method, params, Url);
  //   return res;
  // });

  const commanddata =  { 
    data: data, 
    route: methodRoute, 
    params: params, 
    method: method, 
    source: source, 
    uid: uid 
  };

  socket.emit("Command Sent", commanddata);

};

//functions
const PowerOn = (params, source, uid) => {
  const method = "PowerOnTv";
  TriplePlayApihandler(method, params, source, uid);
};

const PowerOff = (params, source, uid) => {
  const method = "PowerOffTv";
  TriplePlayApihandler(method, params, source, uid);
};

const SelectChannel = (params, source, uid) => {
  const method = "SelectChannel";
  TriplePlayApihandler(method, params, source, uid);
};

const updateVolume = (params, source, uid) => {
  const method = "SetVolume";
  TriplePlayApihandler(method, params, source, uid);
};

const setMute = (params, source, uid) => {
  const method = "SetMute";
  TriplePlayApihandler(method, params, source, uid);
};

export { PowerOn, PowerOff, SelectChannel, updateVolume, setMute };
