import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";

const EditAreaModal = ({
  closeFunction,
  area,
  setConfirmModal,
  confirmModal,
  saveFunction,
}) => {
  const [data, setData] = useState({});
  const [clients, setClients] = useState([]);

  class NewEditArea {
    constructor(areaID, areaName, clients, type) {
      this.areaID = areaID;
      this.areaName = areaName;
      this.clients = clients;
      this.type = type;
    }
  }

  class NewClients {
    constructor(id, name, power, volume) {
      this.id = id;
      this.name = name;
      this.power = power;
      this.volume = volume;
    }
  }

  useEffect(() => {
    let newClients = [];

    area[1].clients.forEach((client) => {
      let newClient = new NewClients(
        client.id,
        client.name,
        client.power,
        client.volume
      );
      newClients.push(newClient);
    });

    setClients(newClients);
  }, []);

  useEffect(() => {
    setData(
      new NewEditArea(area[1].areaID, area[1].areaName, clients, area[1].type)
    );
  }, [clients]);

  const editLocation = (e, key) => {
    data[key] = e.target.value;
  };

  const editClient = (e, key, value) => {
    if (e.target.value === "true") {
      data.clients[key][value] = true;
    } else if (e.target.value === "false") {
      data.clients[key][value] = false;
    } else {
      data.clients[key][value] = e.target.value;
    }
  };

  return (
    <div className="edit-area-popup">
      <div className="edit-area-close-btn-container">
        <GrClose
          className="edit-area-close-btn"
          onClick={() => closeFunction()}
        />
      </div>
      <h2>location: {area[0]}</h2>
      <div className="edit-area-data-container">
        <div className="edit-area-data">
          <p>areaId: {area[1].areaID}</p>
          <p>areaName: {area[1].areaName}</p>
          <div>
            <ul>
              clients:
              {area[1].clients.map((client, key) => {
                return (
                  <li key={key}>
                    <p>id: {client.id} </p>
                    <p>name: {client.name} </p>
                    <p>power: {JSON.stringify(client.power)} </p>
                    <p>volume: {JSON.stringify(client.volume)} </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <p>type: {area[1].type}</p>
        </div>
        <div className="edit-data-form">
          <input
            type="text"
            placeholder="edit area id"
            onChange={(e) => editLocation(e, "areaID")}
          />
          <input
            type="text"
            placeholder="edit area name"
            onChange={(e) => editLocation(e, "areaName")}
          />
          <ul>
            clients:
            {area[1].clients.map((client, key) => {
              return (
                <li key={key}>
                  <input
                    type="text"
                    placeholder={client.id}
                    onChange={(e) => editClient(e, key, "id")}
                  />
                  <input
                    type="text"
                    placeholder={client.name}
                    onChange={(e) => editClient(e, key, "name")}
                  />
                  <input
                    type="text"
                    placeholder={JSON.stringify(client.power)}
                    onChange={(e) => editClient(e, key, "power")}
                  />
                  <input
                    type="text"
                    placeholder={JSON.stringify(client.volume)}
                    onChange={(e) => editClient(e, key, "volume")}
                  />
                </li>
              );
            })}
          </ul>

          <input
            type="text"
            placeholder="edit area type"
            onChange={(e) => editLocation(e, "type")}
          />
        </div>
      </div>
      <div className="edit-data-form-btn-container">
        <button
          className="edit-data-form-btn"
          onClick={() => setConfirmModal(true)}
        >
          save changes
        </button>
        {confirmModal && (
          <div className="confirm-modal">
            <button
              className="confirm-modal-nevermind"
              onClick={() => setConfirmModal(false)}
            >
              never mind
            </button>
            <button
              className="confirm-modal-confirm"
              onClick={() => saveFunction(area[0], data)}
            >
              confirm changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditAreaModal;
