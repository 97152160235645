import Areas from "../data/areas.json";

const restructureFunc = () => {
  let arr = Object.entries(Areas);
  let groups = [];
  let newData = {};

  arr.forEach((area) => {
    if (!groups.includes(area[1].groupName)) {
      groups.push(area[1].groupName);
    }
  });

  groups.forEach((group) => {
    newData[group] = {};
    arr.forEach((area) => {
      if (area[1].groupName === group) {
        newData[group][area[1].areaName] = area;
      }
    });
  });
  return newData;
};

export default restructureFunc;
