import { io } from "socket.io-client";
const VenueConfig = require("./helper/VenueConfig");
// import { VenueConfig } from "./helper/VenueConfig";

let venue = "default";

  let domain = window.location.hostname;
  switch (domain) {
    case "div-remote.ampboards.com":
        venue = "amp";
        break;
    case "dickies-remote.ampboards.com":
        venue = "dickies";
        break;
    case "stlcity-remote.ampboards.com":
        venue = "stlcity";
        break;
    case "iptv.stlcitysc.com":
        venue = "stlcity";
        break;
    case "cfgba.ampboards.com":
        venue = "cfgba";
        break;
    case "sdsu-iptv-control.ampboards.com":
        venue = "snapdragon";
        break;
    case "sap-remote.ampboards.com":
        venue = "sap";
        break;
    case "localhost":
    default: 
        venue = "default";
        break;
  }

  var thisconfig = {
      "venue": venue,
      "hostname": domain
  };

const wsdomain = "https://iptv-ws.ampboards.com";
const wsnamespace = "/" + venue; // + VenueConfig.venue; // don't forget leading slash
const wsserver = wsdomain + wsnamespace;


export const socket = io(wsserver);