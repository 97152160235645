import Config from "../data/config.json";
import { Buffer } from "buffer";

import { socket } from '../socket';

// import axios from "axios";

//constructor
const CiscoVisionApiHandler = (client, method, params, pin, route) => {
  const token = Buffer.from(`${pin}`, "utf8").toString("base64");
  console.log('CV token - ',token);
  // const data = {};
  // const headers = {
  //   Authorization: `Basic ${token}`,
  //   'Access-Control-Request-Method': 'GET',
  // };
  const authtoken = `Basic ${token}`;

  // const headers = {
  //   Authorization: `Basic ${token}`,
  //   'Content-Type': 'application/xml'
  // };
  const headers = {
    'Content-Type': 'application/xml'
  };

  console.log("pin: ", pin);
  // const domain = Config.veserver;
  let domain = "default";

  let thisdomain = window.location.hostname;
  switch (thisdomain) {
    case "dickies-remote.ampboards.com":
      domain = "ciscovision.traildrivemanagementcorp.com";
      break;
    case "stlcity-remote.ampboards.com":
      domain = "stlcsc-wvd-01.stlcitysc.com";
      break;
    case "iptv.stlcitysc.com":
      domain = "stlcsc-wvd-01.stlcitysc.com";
      break;
    default: 
      domain = "default";
      break;
  }


  // const domain = "stlcsc-wvd-01.stlcitysc.com";
  const baseUrl = Config.baseUrl;

  // const Url = `https://${domain}/${route}`;
  // const data = { params: params, headers: headers, method: method }
  const data = { params: params, pin: pin, method: method };
  const methodRoute = `/${method}/${params}/`;

  // const Url = `https://${pin}:@${domain}${baseUrl}/${method}/${params}`;
  const Url = `https://${pin}:@${domain}${baseUrl}/${method}/${params}/`;
  console.log("url: ", Url);

  // axios.post(Url, data).then((res) => {
  //   console.log(res, method, params, Url);
  //   return res;
  // });

  const commanddata =  { 
    data: data, 
    domain: domain,
    baseUrl: baseUrl,
    url: Url, 
    route: methodRoute, 
    params: params, 
    method: method, 
    token: authtoken, 
    client: client 
  };

  socket.emit("Command Sent", commanddata);

  // switch (querytype) {
  //   case "GET": //Individual Player control - requires playerID
  //     axios
  //       .get(Url, data)
  //       .then((res) => {
  //         console.log(res);
  //         return res;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     break;
  //   case "POST": //Group control - requires data with multiple playerIDs
  //     axios
  //       .post(Url, data, headers)
  //       .then((res) => {
  //         console.log(res, method, params, Url);
  //         return res;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     break;
  //   default:
  //     console.log("Wrong Query Type - ", querytype);
  //     break;
  // }
}

//functions
const PowerOn = (params, pin) => {
  // const querytype = "GET";
  const method = "control/player/power/on";
  const route = "/PowerOnTv";
  const client = params[0];
  params = `${params[0]}`;
  //If POST
  params = ``;
  CiscoVisionApiHandler(client, method, params, pin, route);
};

const PowerOff = (params, pin) => {
  // const querytype = "GET";
  const method = "control/player/power/off";
  const route = "/PowerOffTv";
  const client = params[0];
  params = `${params[0]}`;
  //If POST
  params = ``;
  CiscoVisionApiHandler(client, method, params, pin, route);
};

const SelectChannel = (params, pin) => {
  // const querytype = "GET";
  const method = "control/player/channel";
  const route = "/SelectChannel";
  const client = params[0];
  params = `${params[1]}`;
  CiscoVisionApiHandler(client, method, params, pin, route);
};

const updateVolume = (params, pin) => {
  // const querytype = "GET";
  const method = "control/player/volume";
  const route = "/SetVolume";
  const client = params[0];
  //If GET
  // params = `${params[1]}/${params[0]}`;
  //If POST
  params = `${params[1]}`;
  CiscoVisionApiHandler(client, method, params, pin);
};

const setMute = (params, pin) => {
  // const querytype = "GET";
  const method = "control/player/muting";
  const route = "/SetMute";
  const client = params[0];
  //If GET
  params = `${params[1]}/${params[0]}`;
  //If POST
  params = `${params[1]}`;
  CiscoVisionApiHandler(client, method, params, pin, route);
};

export { PowerOn, PowerOff, SelectChannel, updateVolume, setMute };
