export default function areasGetter() {
    let domain = window.location.hostname;
    let venue = 'default';
    switch (domain) {
        case "cfgba.ampboards.com":
        venue = "cfgba";
        break;
        case "iptv.stlcitysc.com":
        venue = "stlcity";
        break;
        default: 
        venue = "cfgba";
        break;
    }
    
    let datapath = './' + venue + '/areas.json';

    return datapath;
}
