import React from "react";
import { useParams } from "react-router-dom";
// import { VenueConfig } from "../helper/VenueConfig";

// styles
import "../styles/header.css";

const VenueConfig = require("../helper/VenueConfig");



// icons
// import BrandLogo from "../assets/img/brand/logo-horizontal-white.png";

const Header = ({ sampleData, tv }) => {
  const { qr_code } = useParams();

  let venue = "default";

  let domain = window.location.hostname;
  switch (domain) {
    case "mlsedemo.ampboards.com":
      venue = "mlse";
      break;
    case "div-remote.ampboards.com":
      venue = "amp";
      break;
    case "dickies-remote.ampboards.com":
      venue = "dickies";
      break;
    case "stlcity-remote.ampboards.com":
      venue = "stlcity";
      break;
    case "iptv.stlcitysc.com":
      venue = "stlcity";
      break;
    case "cfgba.ampboards.com":
      venue = "cfgba";
      break;
    case "sdsu-iptv-control.ampboards.com":
      venue = "snapdragon";
      break;
    case "sap-remote.ampboards.com":
      venue = "sap";
      break;
    case "localhost":
    default: 
        venue = "default";
        break;
  }

  var thisconfig = {
      "venue": venue,
      "hostname": domain
  };

  console.log('Header VC - ', venue);
  // console.dir(VenueConfig());

  // let venue = venue;
  let bucketUrl = 'https://iptv-control-assets.s3.us-west-2.amazonaws.com/' + venue;
  let BrandLogo = bucketUrl + '/logo-horizontal-white.png';
  
  return (
    <div className="header-container">
      <img className="header-logo" src={BrandLogo} alt="brand logo" />
      <h1>
        {sampleData[qr_code] ? sampleData[qr_code].areaName : "Channel Guide"}
      </h1>
      {/* <p>{tv ? tv.name : " - "}</p> */}
    </div>
  );
};

export default Header;
