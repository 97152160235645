import React from "react";
import { useParams } from "react-router-dom";

// import { VenueConfig } from "../helper/VenueConfig.js";

import "../styles/welcome-and-error-page.css";

const VenueConfig = require("../helper/VenueConfig");


// image
// import BrandLogo from "../assets/img/brand/logo-standard-white.png";
const Welcome = ({loadingPage}) => {
  // console.log('Welcome VC - ', venue);
  // console.dir(VenueConfig());
 
  let venue = "default";

  let domain = window.location.hostname;
  switch (domain) {
    case "mlsedemo.ampboards.com":
      venue = "mlse";
      break;
    case "div-remote.ampboards.com":
      venue = "amp";
      break;
    case "dickies-remote.ampboards.com":
      venue = "dickies";
      break;
    case "stlcity-remote.ampboards.com":
      venue = "stlcity";
      break;
    case "iptv.stlcitysc.com":
      venue = "stlcity";
      break;
    case "cfgba.ampboards.com":
      venue = "cfgba";
      break;
    case "sdsu-iptv-control.ampboards.com":
      venue = "snapdragon";
      break;
    case "sap-remote.ampboards.com":
      venue = "sap";
      break;
    case "localhost":
    default: 
      venue = "default";
      break;
  }

  var thisconfig = {
      "venue": venue,
      "hostname": domain
  };
  
  // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // var charactersLength = characters.length;
  // for (var i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  // }

  console.log('Welcome venue - ', venue);
  let bucketUrl = 'https://iptv-control-assets.s3.us-west-2.amazonaws.com/' + venue;
  let BrandLogo = bucketUrl + '/logo-standard-white.png';
  return (
    <div className="welcome-page-container">
      <div className="welcome-img-container">
        <img src={BrandLogo} alt={venue + " logo"} />
      </div>
      <div className="welcome-text-container">
        <h1>Remote Control</h1>
        {loadingPage ? 
          <p>...</p>
        :
          <p>Scan the QR code located on <br /> the tv to get started</p>
        }
        
      </div>
    </div>
  );
};

export default Welcome;
