import React from "react";

// img
import ErrorImg from "../assets/img/Asset Exports/error-icon.png";

const NotFound = () => {
  return (
    <div className="error-page-container">
      <div className="error-page-image-container">
        <img src={ErrorImg} alt="error icon" />
      </div>
      <div className="error-page-text-container">
        <h2>Page Not Found</h2>
        <p>This page seems to have been found in error.</p>
        <p>Please ask staff for assistance.</p>
      </div>
    </div>
  );
};

export default NotFound;
