// react
import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import axios from "axios";

// pages
import Admin from "./pages/Admin";
import NotFound from "./pages/NotFound";
import Welcome from "./pages/Welcome";
// import Login from "./pages/Login";

//component
// import Restricted from "./components/Restricted";

// styles
import "./App.css";
import Config from "./data/config.json";
// import areas from "https://ampthink-fonts.s3.us-west-2.amazonaws.com/sdsu-config/areas.json" assert { type: "json" };

import { socket } from './socket';
import { ConnectionState } from './components/sockethelpers/ConnectionState';
import { ConnectionManager } from './components/sockethelpers/ConnectionManager';
import { Events } from './components/sockethelpers/Events';

import * as fs from 'fs';

const convert = require('xml-js');



const ControlApp = lazy(() => import("./pages/ControlApp"));

const areas = require('./data/areasGetter');

console.log('APP.js: areas - ');
console.log(areas);

// let areapath = "./data/" + venue + "/areas.json";
// const areas= fs.readFileSync(areapath, {
//   encoding: 'utf-8',
// });;

function App() {
  const [isLoading, setLoading] = useState(true);
  const [loggedIn, isLoggedIn] = useState(false);
  // const domain = Config.serverdomain;
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  let venue = "default";
  // let areas = {};

  let domain = window.location.hostname;
  switch (domain) {
    case "mlsedemo.ampboards.com":
      venue = "mlse";
      setPrimaryColor("#b49f83");
      break;
    case "div-remote.ampboards.com":
      venue = "amp";
      break;
    case "dickies-remote.ampboards.com":
      venue = "dickies";
      setPrimaryColor("#282829");
      break;
    case "cfgba.ampboards.com":
      venue = "cfgba";
      break;
    case "iptv.stlcitysc.com":
      venue = "stlcity";
      break;
    case "sap-remote.ampboards.com":
      venue = "sap";
      setPrimaryColor("#006b8a");
      break;
    default: 
      venue = "default";
      setPrimaryColor("#333");
      break;
  }

  function setPrimaryColor(_color) {
    document.documentElement.style.setProperty('--primary-color', _color);
  }
  // let areapath = './data/' + venue + '/areas.json';
  // areas = require(areapath);
  // console.log(areapath);
  // console.dir(areas);

  useEffect(() => {
    function onConnect() {
      console.log('Testing connect');
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    // function onFooEvent(value) {
    //   setFooEvents(previous => [...previous, value]);
    // }

    function updateRequestReceived(updateRequest) {
      let type = updateRequest.type;
      let method = updateRequest.method;
      let data = updateRequest.data;

      const xml = convert.xml2json(data, {compact: true, spaces: 4});

      const requestjson = JSON.parse(xml);

      console.log('AreasUpdateRequest:', requestjson);

      switch (type) {
        case ("areas"):
          if (method == "update") {
            console.log(type + ": " + method);
          } else if (method == "replace") {
            console.log(type + ": " + method);
          } else {
            console.log(type + ": unknown method - " + method);
          }
          break;
      }
      // console.dir(updateRequest);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    // socket.on('foo', onFooEvent);
    socket.on('update request', updateRequestReceived);
    // socket.on('test', testFunction);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      // socket.off('foo', onFooEvent);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {/* <br/><br/><br/>
        <ConnectionManager />
        <Events events={fooEvents} />
        <ConnectionState isConnected={isConnected} /> */}
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route 
            path={`/qr/:qr_code`} 
            element={
            <Suspense fallback={<Welcome loadingPage={true} />} >
              <ControlApp venue={venue}/>
            </Suspense>
            } 
          />
          <Route 
            path={`/admin/1337420`} 
            element={
            <Suspense fallback={<Welcome loadingPage={true} />} >
              <Admin venue={venue} />
            </Suspense>
            } 
          />
          {/* <Route path={`/secret/:admin`} element={<Admin />} /> */}
          {/* <Route
            path="/s/sahg4e"
            element={
              <Restricted
                areas={areas}
                loggedIn={loggedIn}
                isLoggedIn={isLoggedIn}
              />
            }
          /> */}
          {/* <Route
            path="/login"
            element={<Login loggedIn={loggedIn} isLoggedIn={isLoggedIn} />}
          /> */}
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
