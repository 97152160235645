const venueconfig = () => {
    return setConfig();
}

function setConfig() {
    let venue = "default";

    let domain = window.location.hostname;
    switch (domain) {
        case "iptvremote.ampboards.com":
            venue = "snapdragon";
            break;
        case "stlcity-remote.ampboards.com":
            venue = "stlcity";
            break;
        case "iptv.stlcitysc.com":
            venue = "stlcity";
            break;
        case "cfgba.ampboards.com":
            venue = "cfgba";
            break;
        case "sdsu-iptv-control.ampboards.com":
            venue = "snapdragon";
            break;
        case "sap-remote.ampboards.com":
            venue = "sap";
            break;
        case "localhost":
        default: 
            venue = "default";
            break;
    }

    var thisconfig = {
        "venue": venue,
        "hostname": domain
    };
    
    // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // var charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    return thisconfig;
}

module.exports = venueconfig;