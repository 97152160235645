import { useEffect, useState } from "react";
import axios from "axios";
import Areas from "../data/areas.json";
import restructureFunc from "../helper/GroupsFunction";
import Header from "../components/Header";
import EditAreaModal from "../components/EditAreaModal";
import Config from "../data/config.json";

import "../styles/newAdmin.css";

const Admin = ( venue ) => {
  const domain = Config.serverdomain;

  const [editPopUp, setEditPopup] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [area_data, setAreaData] = useState();
  const [arr, setArr] = useState();

  var dataToDownload =
    "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(Areas));

  useEffect(() => {
    setArr(Object.keys(Areas));
  }, []);

  const directorType = "VE";

  let APIHandler = {};

  if (directorType == "TP") {
    APIHandler = require("../helper/TriplePlayApihandler");
  } else if(directorType == "VE") {
    APIHandler = require("../helper/VisionEdgeApiHandler");
  } else if(directorType == "CV") {
    APIHandler = require("../helper/CiscoVisionApiHandler");
  }

  const editFunction = (area) => {
    setAreaData([area, Areas[area]]);
    setEditPopup(true);
  };

  const closeFunction = () => {
    setEditPopup(false);
    setConfirmModal(false);
  };

  const saveFunction = (key, data) => {
    setEditPopup(false);
    setConfirmModal(false);

    axios
      .post(`${domain}/save-json`, {
        key,
        data,
      })
      .then((res) => {
        console.log(res);
      });
  };

  const askForChannelData = () => {
    APIHandler.getChannelGuide("","8378725","172.17.0.55");
    APIHandler.getChannelGuide("","7839099","172.17.0.40");
    
  }
  const askForAreasData = () => {
    APIHandler.getSuiteInfo("","8378725","172.17.0.55");
    APIHandler.getSuiteInfo("","7839099","172.17.0.40");
  }

  return (
    <div className="admin-container">
      <Header sampleData={Areas} />
      <div className="areas-container">
        <button onClick={askForChannelData}>Channel Info</button>
        <hr/>
        <button onClick={askForAreasData}>Suite Info</button>
        {/* <div style={{ width: "100%", textAlign: "center", padding: "25px" }}>
          <a
            className="admin-json-download-btn"
            href={"data:" + JSON.stringify(dataToDownload)}
            download="../data/areas.json"
          >
            Download JSON
          </a>
        </div>
        {arr &&
          arr.map((location, key) => {
            return (
              <div
                className="area-container"
                key={key}
                onClick={() => editFunction(location)}
              >
                <p>{Areas[location].areaName}</p>
              </div>
            );
          })}
        {editPopUp && (
          <EditAreaModal
            closeFunction={closeFunction}
            area={area_data}
            setConfirmModal={setConfirmModal}
            confirmModal={confirmModal}
            saveFunction={saveFunction}
          />
        )} */}
      </div>
    </div>
  );
};

export default Admin;
